import {
  type JobType,
  type JobsCollectionSortField,
  OrderDirection,
  type ShiftType,
} from '@generated/graphql';
import { JOB_PAGES_SSG_SETTINGS_PROPS, JobPageType } from '@src/constants';
import {
  JobsPageLandingPage,
  PAGE_SIZE,
} from '@src/containers/jobs-page/jobs-page-landing-page';
import { hygraphClient } from '@src/lib/hygraph-client';
import {
  JOBS_LANDING_PAGE_PAGE_CONTENT_QUERY,
  JOBS_PAGE_LANDING_PAGE_CONTENT_QUERY,
  type JobsPageContentData,
  type JobsPageLandingPageContentData,
  type JobsPageSpecialtyUnit,
  getAllJobSpecialtiesUnits,
  getValidRolesPageData,
} from '@src/queries/jobs-page-content-query';
import type { GetServerSideProps } from 'next';

export default JobsPageLandingPage;

export const getServerSideProps: GetServerSideProps = async () => {
  const { data: pageData } = await hygraphClient.query<JobsPageContentData>({
    query: JOBS_LANDING_PAGE_PAGE_CONTENT_QUERY,
  });

  const { data: landingPageData } =
    await hygraphClient.query<JobsPageLandingPageContentData>({
      query: JOBS_PAGE_LANDING_PAGE_CONTENT_QUERY,
    });

  const nursingJobPageSpecialtiesUnits: JobsPageSpecialtyUnit[] =
    await getAllJobSpecialtiesUnits({ jobType: JobPageType.Nursing });

  const alliedJobPageSpecialtiesUnits: JobsPageSpecialtyUnit[] =
    await getAllJobSpecialtiesUnits({ jobType: JobPageType.Allied });

  const initialQueryParams = {
    filters: {
      locationString: '',
      clinicalUnitSlugs: [],
      specialtySlugs: [],
      jobType: 'TRAVEL' as JobType,
      role: '',
      shiftTypes: ['day', 'evening', 'night', 'variable'] as ShiftType[],
    },
    sortBy: {
      field: 'GROSS_PAY_WEEKLY' as JobsCollectionSortField,
      direction: OrderDirection.Desc,
    },
    paginate: {
      page: 1,
      pageSize: PAGE_SIZE,
    },
    includeClinicalUnit: false,
    includeLocation: false,
  };

  return {
    props: {
      nursingJobPageSpecialtiesUnits,
      alliedJobPageSpecialtiesUnits,
      pageData: { ...pageData, jobRolePages: [] },
      initialQueryParams,
      landingPageData: landingPageData.jobsLandingPages[0],
      roles: getValidRolesPageData(pageData.jobRolePages),
      props: JOB_PAGES_SSG_SETTINGS_PROPS,
    },
  };
};
