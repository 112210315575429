import TrustedWordmark from '@public/static/trusted_wordmark_black.svg';
import type { JobsPageSpecialtyUnit } from '@src/queries/jobs-page-content-query';
import {
  DSBodyText,
  DSHeader,
  DSOverline,
} from '@src/ui/jobs-ds/common/typography';

import { SignupButton } from '@src/components/signup-button';
import { MOBILE_MAX_WIDTH } from '@src/constants';
import clsx from 'clsx';
import Link from 'next/link';
interface Props {
  jobPageSpecialtiesUnits?: JobsPageSpecialtyUnit[];
  hideSpecialtyUnits?: boolean;
  roleSlug?: string;
  isMobile?: boolean;
}
export function JobsFooter({
  jobPageSpecialtiesUnits,
  hideSpecialtyUnits,
  roleSlug,
  isMobile,
}: Props) {
  return (
    <>
      <div className="jobs-footer__banner">
        <DSHeader size={isMobile ? 5 : 2}>
          Helping nurses and allied professionals build the lives they want.
        </DSHeader>
        <SignupButton source="external-jobs" />
      </div>
      <div
        className={clsx('jobs-footer', {
          'jobs-footer-full': hideSpecialtyUnits,
        })}
      >
        <div className="jobs-footer__about">
          <a href="https://trustedhealth.com">
            <TrustedWordmark
              style={{
                height: '100%',
                width: '11.25em',
                marginBottom: '1.375em',
              }}
            />
          </a>
          <DSOverline bold>WE&apos;RE HERE TO HELP</DSOverline>
          <div className="jobs-footer__about-contact">
            <a href="tel:+14154661466">
              <DSBodyText bold small>
                Call us (415-466-1466)
              </DSBodyText>
            </a>
            <a
              href="mailto:contact@trustedhealth.com"
              className="footer-contact-link"
            >
              <DSBodyText bold small>
                Email us
              </DSBodyText>
            </a>
          </div>
          <div className="jobs-footer__about-socials">
            <DSOverline bold>CONNECT WITH US</DSOverline>
            <div className="jobs-footer__about-socials__icons">
              <a
                href="https://www.instagram.com/trustedhealth/"
                target="_blank"
                className="social-link w-inline-block"
                rel="noreferrer"
              >
                <img
                  src="/static/svgs/brand/instagram.svg"
                  loading="lazy"
                  alt="Trusted health instagram "
                />
              </a>
              <a
                href="https://www.facebook.com/trustedhealth"
                target="_blank"
                className="social-link w-inline-block"
                rel="noreferrer"
              >
                <img
                  src="/static/svgs/brand/facebook.svg"
                  loading="lazy"
                  alt="Trusted Health facebook"
                />
              </a>
              <a
                href="https://twitter.com/trustedhealth"
                target="_blank"
                className="social-link w-inline-block"
                rel="noreferrer"
              >
                <img
                  src="/static/svgs/brand/twitter.svg"
                  loading="lazy"
                  alt="Trusted Health twitter"
                />
              </a>
              <a
                href="https://www.youtube.com/trustedhealth"
                target="_blank"
                className="social-link w-inline-block"
                rel="noreferrer"
              >
                <img
                  src="/static/svgs/brand/youtube.svg"
                  loading="lazy"
                  alt="Trusted Health youtube page"
                />
              </a>
            </div>
          </div>
          <div className="jobs-footer__about-copyright">
            <DSBodyText>Copyright Trusted Inc, 2023</DSBodyText>
          </div>
          <div className="jobs-footer__about-terms">
            <a href="https://www.trustedhealth.com/terms-of-service">
              <DSBodyText underline>Terms & Conditions</DSBodyText>
            </a>{' '}
            |{' '}
            <a href="https://www.trustedhealth.com/privacy-policy">
              <DSBodyText underline>Privacy</DSBodyText>
            </a>{' '}
            |{' '}
            <a href="mailto:legal@trustedhealth.com">
              <DSBodyText underline>Legal</DSBodyText>
            </a>
          </div>
        </div>
        {!hideSpecialtyUnits ? (
          <div className="jobs-footer__specialties-container">
            <div className="jobs-footer__specialties">
              {jobPageSpecialtiesUnits
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(specialty => (
                  <Link
                    prefetch={false}
                    key={specialty.id}
                    href={{
                      pathname:
                        '/jobs/[jobType]/specialties-units/[specialtyUnit]',
                      query: {
                        specialtyUnit: specialty.slug,
                        jobType: roleSlug,
                      },
                    }}
                  >
                    <DSBodyText underline small>
                      {specialty.name}
                    </DSBodyText>
                  </Link>
                ))}
            </div>
          </div>
        ) : null}
      </div>
      <style jsx>
        {`
          .jobs-footer__banner {
            background: url(/static/jobs/banner.jpg);
            min-height: 11rem;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding: 2rem;
            margin-top: 5rem;
          }
          .jobs-footer {
            display: flex;
            width: 100%;
            padding: 4.5rem 12.75rem;
            flex-direction: row-reverse;
            align-items: flex-start;
          }
          .jobs-footer__about {
            display: flex;
            flex-direction: column;
            min-width: 16.125rem;
          }
          .jobs-footer-full {
            justify-content: center;
          }
          .jobs-footer__about-contact {
            margin-top: 0.75rem;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          }
          .jobs-footer__about-socials {
            margin-top: 2.125rem;
          }
          .jobs-footer__about-socials__icons {
            margin-top: 1rem;
            gap: 1rem;
            display: flex;
          }
          .jobs-footer__about-copyright {
            margin-top: 1.5rem;
          }
          .jobs-footer__about-terms {
            margin-top: 0.5rem;
          }
          .jobs-footer__specialties-container {
            width: 100%;
          }
          .jobs-footer__specialties {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(
              auto-fit,
              minmax(max-content, 16.125rem)
            );
            column-gap: 1.375rem;
            row-gap: 1rem;
          }
          @media (max-width: ${MOBILE_MAX_WIDTH}) {
            .jobs-footer__banner {
              padding: 1rem;
            }
            .jobs-footer__about {
              width: 100%;
              align-items: center;
              padding: 1.5rem 0;
            }
            .jobs-footer__specialties-container {
              padding: 1rem;
            }
            .jobs-footer {
              flex-direction: column;
              padding: 0;
            }
          }
        `}
      </style>
    </>
  );
}
