import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { DefaultSeo } from 'next-seo';

import {
  DirectoryHeader,
  DirectoryHeaderBar,
  DirectoryNavigation,
} from '@src/ui';

import { Breadcrumbs, JobsPageHeader } from '@src/components/jobs-page';
import { TrustedHead } from '@src/components/trusted-head';

import { JobsFiltersLandingPage } from '@src/components/jobs-page/jobs-filters';
import type { RoleItem } from '@src/components/jobs-page/jobs-filters/role-item';
import { JobsFooter } from '@src/components/jobs-page/jobs-footer/jobs-footer';
import {
  DESKTOP_MIN_WIDTH,
  JOB_URL_TYPE,
  MOBILE_MAX_WIDTH,
} from '@src/constants';
import { useJobsPageContext } from '@src/contexts/jobs-page';
import { useIsMobile } from '@src/hooks/use-is-mobile';
import { analytics } from '@src/lib/analytics';
import type {
  JobsPageContentData,
  JobsPageLandingPageType,
  JobsPageSpecialtyUnit,
} from '@src/queries/jobs-page-content-query';
import { neutralLower } from '@src/support/colors';
import { DSHeader } from '@src/ui/jobs-ds/common/typography';
import type { JobsPageSearchParams } from './types';
import { navigate, replaceEmptyTagElements } from './utils';

export const PAGE_SIZE = 10;

export function JobsPageLandingPage({
  nursingJobPageSpecialtiesUnits,
  alliedJobPageSpecialtiesUnits,
  pageData,
  roles,
  landingPageData,
}: {
  nursingJobPageSpecialtiesUnits: JobsPageSpecialtyUnit[];
  alliedJobPageSpecialtiesUnits: JobsPageSpecialtyUnit[];
  pageData: JobsPageContentData;
  roles: RoleItem[];
  landingPageData: JobsPageLandingPageType;
}) {
  const router = useRouter();
  const isMobile = useIsMobile();
  const { setNewQueryParams } = useJobsPageContext();

  const { navigations: pageNavigation } = pageData;

  useEffect(() => {
    analytics.page('jobs-pip');
  }, []);

  const handleQueryParamsChange = useCallback(
    (newSearchFilters: JobsPageSearchParams) => {
      if (setNewQueryParams) {
        setNewQueryParams(newSearchFilters);
      }

      // only redirect if has just one specialtyUnit selected or if has only one parent selected.
      if (
        newSearchFilters.jobType &&
        (newSearchFilters.specialtyUnits?.length === 0 ||
          newSearchFilters.specialtyUnits?.length === 1 ||
          newSearchFilters.specialtyUnits?.filter(item => item.isParent)
            .length === 1)
      ) {
        const parent = newSearchFilters.specialtyUnits?.find(
          item => item.isParent
        );

        navigate(router, {
          role: newSearchFilters.roleSlug,
          specialtyUnit: parent ? parent : newSearchFilters?.specialtyUnits[0],
          jobType: newSearchFilters.jobType,
          page: 1,
        });
      } else if (
        newSearchFilters.jobType &&
        newSearchFilters.specialtyUnits &&
        newSearchFilters.specialtyUnits?.length > 1
      ) {
        navigate(router, {
          role: newSearchFilters.roleSlug,
          jobType: newSearchFilters.jobType,
          page: 1,
        });
      }
    },
    [router, setNewQueryParams]
  );

  return (
    <div className="JobsPage">
      <TrustedHead
        title="Travel Healthcare Jobs | Trusted Health"
        description="Find your dream travel healthcare job with Trusted®. Highest Pay in the industry. Health insurance & 401(K) from day one. Apply today!"
      />

      <DefaultSeo canonical="https://www.trustedhealth.com/jobs" />

      <DirectoryHeader sticky>
        <DirectoryHeaderBar>
          <JobsPageHeader pageNavigation={pageNavigation} />
        </DirectoryHeaderBar>
      </DirectoryHeader>

      <div className="JobsPageMainTitleContainer">
        <span role="img" aria-label={landingPageData.titleBackgroundImageAlt} />
        <div className="JobsPageMainSection">
          {!isMobile && (
            <div className="JobsPageMainTitleSectionTitle">
              <div className="JobsPageMainTitleSectionTitleMain">
                <DSHeader className="" size={1}>
                  {landingPageData.title}
                </DSHeader>
              </div>
              <DSHeader size={4}>{landingPageData.titleTagline}</DSHeader>
            </div>
          )}
        </div>
      </div>

      <div className="JobsPageMainSection JobsPageFilterSection">
        <JobsFiltersLandingPage
          nursingSpecialtiesUnits={nursingJobPageSpecialtiesUnits}
          alliedpecialtiesUnits={alliedJobPageSpecialtiesUnits}
          roles={roles}
          initialParams={{
            roleSlug: '',
            shiftTypes: [],
            locationStrings: [],
            specialtyUnits: [],
          }}
          onSubmit={handleQueryParamsChange}
          filterTitle={landingPageData.filterTitle}
        />
      </div>

      <div className="JobsPageMainSection">
        <div className="JobsPageBodyContent">
          <DirectoryNavigation>
            <Breadcrumbs />
          </DirectoryNavigation>
          <div className="JobsPageBodySubTitle">
            <DSHeader size={2}>{landingPageData.subTitle}</DSHeader>
            <div className="JobsPageBodySubTitleDescription">
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceEmptyTagElements(
                    landingPageData.subTitleDescription.html
                  ),
                }}
              />
              {!isMobile && (
                <img
                  className="JobsPageBodySubTitleDescriptionImage"
                  src={landingPageData.subTitleImage.url}
                  alt={landingPageData.subTitleImageAlt}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <JobsFooter
        jobPageSpecialtiesUnits={nursingJobPageSpecialtiesUnits}
        roleSlug={JOB_URL_TYPE.TravelNurse}
      />
      <style jsx>
        {`
          :global(html, body, #__next) {
            background: ${neutralLower};
          }
          .JobsPage {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
          }

          .JobsPageMainTitleContainer {
            height: 25em;
            background-image: url(${landingPageData.titleBackgroundImage.url});
            background-position: center;
            background-size: cover;
            display: flex;
            align-items: center;
            color: white;
          }

          .JobsPageBodySubTitle {
            padding: 1.25em 0;
          }

          .JobsPageBodyContent {
            padding-top: 2.5em;
          }

          .JobsPageBodySubTitleDescription {
            line-height: 1.5em;
            display: flex;
          }

          .JobsPageBodySubTitleDescriptionImage {
            display: block;
            max-width: 300px;
            max-height: 250px;
            width: auto;
            height: auto;
            margin-left: 1.25em;
            border-radius: 0.5em;
          }

          .JobsPageMainTitleSectionTitleMain {
            margin-bottom: 0.625em;
          }

          .JobsPageFilterSection {
            margin-top: -5em;
          }

          @media (min-width: ${DESKTOP_MIN_WIDTH}) {
            .JobsPageMainSection {
              margin-left: auto;
              margin-right: auto;
              max-width: 75em;
              width: 100%;
            }

            .JobsPageMainTitleSectionTitle {
              width: 60%;
            }

            .JobsPageMainTitleContainer {
              height: 18.75em;
            }
          }

          @media (max-width: ${MOBILE_MAX_WIDTH}) {
            .JobsPageMainSection {
              padding: 1.25em;
            }

            .JobsPageBodySubTitle {
              padding: 0;
            }

            .JobsPageMainTitleContainer {
              height: 12.5em;
            }

            .JobsPageBodyContent {
              padding-top: 0.625em;
            }
          }

          @media screen and (min-width: 120em) {
            .JobsPageMainSection {
              max-width: 87.5em;
            }
          }
        `}
      </style>
    </div>
  );
}
