import { white } from '@src/support/colors';
import clsx from 'clsx';
import { useContext } from 'react';
import type { ReactNode } from 'react';

import { CollapsibleContext } from './collapsible-context';

export const CollapsibleContent = ({ children }: { children?: ReactNode }) => {
  const context = useContext(CollapsibleContext);
  return (
    <div className={clsx('CollapsibleContent', { open: context.open })}>
      {children}
      <style jsx>{`
        .CollapsibleContent {
          overflow: hidden;
          transition: max-height 300ms ease-out;
          max-height: 0;
          background-color: ${white};
          border-radius: 0 0 0.25rem 0.25rem;
        }

        .CollapsibleContent.open {
          max-height: 800px;
          height: auto;
          transition: max-height 300ms ease-in;
          overflow: auto;
        }
      `}</style>
    </div>
  );
};
