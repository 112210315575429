import type { ParsedUrlQueryInput } from 'node:querystring';

export function buildAsPath(
  pathname: string,
  params: ParsedUrlQueryInput = {}
) {
  let nextPath = pathname;
  const query = Object.assign({}, params);
  for (const key in query) {
    nextPath = nextPath.replace(
      new RegExp(`\\[(${key})\\]`, 'g'),
      (_match, p1) => {
        const value = query[p1];
        delete query[p1];
        return `${value}`;
      }
    );
  }

  return { pathname: nextPath, query };
}
