import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { GoogleMapsScriptProvider } from '@src/contexts/google-maps-script-provider';
import {
  AutoCompleteMultiSelect,
  type SelectItem,
} from '@src/ui/auto-complete-multi-select';

type Prediction = google.maps.places.AutocompletePrediction;

type Props = {
  initialLocations: string[];
  onSubmit: (locations: string[]) => void;
};

export const JobsLocationFilter = ({
  initialLocations = [],
  onSubmit,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>(
    initialLocations.map(locationString => ({
      label: <>{locationString}</>,
      value: locationString,
      textTerm: locationString,
    }))
  );
  const [predictions, setPredictions] = useState<Prediction[]>();
  const [mapsLoaded, setMapsLoaded] = useState<boolean>(false);
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);

  const autocompleteService = useMemo<
    google.maps.places.AutocompleteService | undefined
  >(() => {
    if (mapsLoaded && window && window.google && window.google.maps) {
      return new window.google.maps.places.AutocompleteService();
    }
  }, [mapsLoaded]);

  useEffect(() => {
    setSelectedItems(
      initialLocations.map(locationString => ({
        label: <>{locationString}</>,
        value: locationString,
        textTerm: locationString,
      }))
    );
  }, [initialLocations]);

  const placeholder = useMemo(() => {
    if (selectedItems.length > 1) {
      return `${selectedItems.length} Locations Selected`;
    }

    if (selectedItems.length === 1) {
      return selectedItems[0].value;
    }

    return 'All Locations';
  }, [selectedItems]);

  const handlePredictionsChange = useCallback(
    (
      predictions: Prediction[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        console.error('error', status);
      } else {
        const newItems = predictions ?? [];
        setPredictions(
          newItems.map(prediction => {
            prediction.description = prediction.description.replace(
              ', USA',
              ''
            );
            return prediction;
          })
        );
      }
    },
    []
  );

  useEffect(() => {
    if (searchTerm.trim() !== '' && autocompleteService) {
      autocompleteService.getPlacePredictions(
        {
          input: searchTerm,
          types: ['(regions)'],
          componentRestrictions: { country: 'us' },
        },
        handlePredictionsChange
      );
    }
  }, [searchTerm, autocompleteService, handlePredictionsChange]);

  const handleApply = (items: SelectItem[]) => {
    if (onSubmit) {
      onSubmit(items.map(item => item.value));
    }
  };

  const handleClear = () => {
    setPredictions([]);
    setSelectedItems([]);
    setShouldSubmit(true);

    if (onSubmit) {
      onSubmit([]);
    }
  };

  const handleClose = (items: SelectItem[]) => {
    if (onSubmit && shouldSubmit) {
      onSubmit(items.map(item => item.value));
    }
  };

  const handleSelect = (items: SelectItem[], selected: boolean) => {
    const itemsMap = {
      ...selectedItems.reduce((map: Record<string, boolean>, item) => {
        map[item.value] = true;
        return map;
      }, {}),
      ...items.reduce((map: Record<string, boolean>, item) => {
        map[item.value] = selected;
        return map;
      }, {}),
    };

    setSelectedItems(
      Object.keys(itemsMap).reduce((list: SelectItem[], item) => {
        if (itemsMap[item]) {
          list.push({
            label: <>{item}</>,
            value: item,
            textTerm: item,
          });
        }

        return list;
      }, [])
    );

    setShouldSubmit(true);
  };

  const handleSearch = useMemo(
    () =>
      debounce((term: string) => {
        setSearchTerm(term);
      }, 300),
    []
  );

  const availableItems = useMemo(() => {
    if (predictions) {
      return predictions.map(prediction => ({
        label: <>{prediction.description}</>,
        value: prediction.description,
        textTerm: prediction.description,
      }));
    }

    return initialLocations.map(location => ({
      label: <>{location}</>,
      value: location,
      textTerm: location,
    }));
  }, [initialLocations, predictions]);

  return (
    <GoogleMapsScriptProvider onLoad={() => setMapsLoaded(true)}>
      <AutoCompleteMultiSelect
        items={availableItems}
        filterItems={false}
        placeholder={placeholder}
        focusedPlaceholder="Search City or State"
        initialSelectedItems={selectedItems}
        onApply={handleApply}
        onClear={handleClear}
        onClose={handleClose}
        onSearch={handleSearch}
        onSelect={handleSelect}
      />
    </GoogleMapsScriptProvider>
  );
};
