import { MOBILE_MAX_WIDTH } from '@src/constants/breakpoints';
import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

type Props = {
  sticky?: boolean;
};

export function DirectoryHeader({
  sticky = false,
  children,
}: PropsWithChildren<Props>) {
  return (
    <header className={clsx('DirectoryHeader', { sticky: sticky })}>
      {children}
      <style jsx>{`
        .DirectoryHeader {
          background-color: ${colors.black};
          box-shadow: 0 0.0625em 0.0625em ${rgba(colors.black, 1)};
          min-width: 5em;
          font-size: 1rem;
          position: relative;
        }

        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          .DirectoryHeader.sticky {
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;
          }
        }
      `}</style>
    </header>
  );
}
