import { DSBreadcrumbs } from '@src/ui/jobs-ds/common/breadcrumbs';
import { useRouter } from 'next/router';
import {
  type Props,
  generateJobsBreadcrumbLinks,
} from './generate-jobs-breadcrumb-links';

export function Breadcrumbs(props: Props) {
  const router = useRouter();
  const links = generateJobsBreadcrumbLinks(router.pathname, props);

  return <DSBreadcrumbs links={links} />;
}
