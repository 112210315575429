import type { RoleItem } from '@src/components/jobs-page/jobs-filters/role-item';
import { JOB_URL_TYPE, getStateSlug } from '@src/constants';
import type { BreadcrumbLink } from '@src/ui/jobs-ds/common/breadcrumbs';

const JOB_TYPE_TEXT_MAP = {
  'travel-nurse': 'Travel Nurse Jobs',
  'travel-allied': 'Travel Allied Jobs',
};

export type Props = {
  jobType?: JOB_URL_TYPE;
  stateCode?: string;
  stateName?: string;
  city?: string;
  role?: RoleItem;
  unitName?: string;
  unitSlug?: string;
};

export function generateJobsBreadcrumbLinks(
  pathname: string,
  {
    jobType = JOB_URL_TYPE.TravelNurse,
    role,
    city,
    stateCode,
    stateName,
    unitName,
    unitSlug,
  }: Props
) {
  const links: BreadcrumbLink[] = [
    {
      href: 'https://www.trustedhealth.com',
      isExternal: true,
      title: 'Home',
    },
    {
      href: `/jobs/${jobType}`,
      title: JOB_TYPE_TEXT_MAP[jobType],
    },
  ];

  let path = `/jobs/${jobType}`;

  if (pathname === '/jobs') {
    return [
      {
        href: 'https://www.trustedhealth.com',
        isExternal: true,
        title: 'Home',
      },
      {
        title: 'Travel Healthcare Jobs',
      },
    ];
  }

  if (pathname.includes('/jobs/[id]')) {
    const stateSlug = getStateSlug(stateCode!);
    return [
      {
        href: 'https://www.trustedhealth.com',
        isExternal: true,
        title: 'Home',
      },
      {
        href: path,
        title: JOB_TYPE_TEXT_MAP[jobType],
      },
      {
        href: `${path}/roles/${role?.slug}`,
        title: role?.filterName,
      },
      {
        href: `${path}/roles/${role?.slug}/units/${unitSlug}`,
        title: unitName,
      },
      {
        href: `${path}/roles/${role?.slug}/units/${unitSlug}/states/${stateSlug}`,
        title: stateName,
      },
      { title: city },
    ];
  }

  if (pathname.includes('/roles/[role]')) {
    path = `${path}/roles/${role?.slug}`;

    if (pathname.includes('units') || pathname.includes('states')) {
      links.push({
        href: path,
        title: role?.filterName,
      });
    } else {
      links.push({ title: role?.filterName });
    }
  }

  if (pathname.includes('/units/[unit]')) {
    path = `${path}/units/${unitSlug}`;

    if (pathname.includes('states')) {
      links.push({
        href: path,
        title: unitName,
      });
    } else {
      links.push({ title: unitName });
    }
  }

  if (pathname.includes('/specialties-units/[specialtyUnit]')) {
    path = `${path}/specialties-units/${unitSlug}`;

    if (pathname.includes('states')) {
      links.push({
        href: path,
        title: unitName,
      });
    } else {
      links.push({ title: unitName });
    }
  }

  if (pathname.includes('/states/[state]')) {
    if (city) {
      links.push({ title: `${city}, ${stateName}` });
    } else {
      links.push({ title: stateName });
    }
  }

  return links;
}
