import { useMemo, useState } from 'react';

import { JOB_PAGE_TYPES, JOB_URL_TYPE } from '@src/constants';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MAX_WIDTH,
} from '@src/constants/breakpoints';
import type { JobsPageSearchParams } from '@src/containers/jobs-page/types';
import type { JobsPageSpecialtyUnit } from '@src/queries/jobs-page-content-query';
import * as colors from '@src/support/colors';
import { Button } from '@src/ui';
import { AutoCompleteMultiSelect } from '@src/ui/auto-complete-multi-select';
import type { SelectItem } from '@src/ui/auto-complete-multi-select/multi-select-item';
import { DSHeader } from '@src/ui/jobs-ds/common/typography';
import { JobsDropdownSelect } from '../jobs-dropdown-select';
import {
  buildSelectedItems,
  buildSpecialtyUnitItems,
  buildSpecialtyUnitPlaceholder,
  getSelectedSpecialtyUnits,
} from './jobs-filters';
import type { RoleItem } from './role-item';

type Props = {
  nursingSpecialtiesUnits: JobsPageSpecialtyUnit[];
  alliedpecialtiesUnits: JobsPageSpecialtyUnit[];
  roles: RoleItem[];
  initialParams: JobsPageSearchParams;
  onSubmit: (newSearchFilters: JobsPageSearchParams) => void;
  filterTitle: string;
};

export function JobsFiltersLandingPage({
  initialParams,
  nursingSpecialtiesUnits = [],
  alliedpecialtiesUnits = [],
  roles,
  onSubmit,
  filterTitle = 'Find your dream travel healthcare jobs',
}: Props) {
  const [jobUrlType, setJobUrlType] = useState<JOB_URL_TYPE | ''>('');
  const [role, setRole] = useState<string | ''>('');
  const [specialtyUnits, setSpecialtyUnits] = useState<SelectItem[]>(
    initialParams.specialtyUnits
      ? buildSelectedItems(initialParams.specialtyUnits)
      : []
  );

  const filteredRoles = useMemo(() => {
    if (jobUrlType) {
      return roles.filter(
        ({ jobType }) => jobType === JOB_PAGE_TYPES[jobUrlType]
      );
    }

    return [];
  }, [roles, jobUrlType]);

  const filteredUnits = useMemo(() => {
    if (jobUrlType === JOB_URL_TYPE.TravelNurse) {
      return nursingSpecialtiesUnits;
    }

    if (jobUrlType === JOB_URL_TYPE.TravelAllied) {
      return alliedpecialtiesUnits;
    }

    return [];
  }, [nursingSpecialtiesUnits, alliedpecialtiesUnits, jobUrlType]);

  const specialtyUnitItems = useMemo(
    () => buildSpecialtyUnitItems(filteredUnits, jobUrlType),
    [filteredUnits, jobUrlType]
  );

  const handleFormSubmit = () => {
    if (
      (initialParams.roleSlug !== role ||
        initialParams?.roleSlug === '' ||
        (specialtyUnits || [])?.length > 0) &&
      jobUrlType
    ) {
      const selectedSpecialtyUnits = getSelectedSpecialtyUnits(
        filteredUnits,
        specialtyUnits
      );

      onSubmit({
        jobType: jobUrlType,
        roleSlug: role,
        specialtyUnits: selectedSpecialtyUnits || undefined,
        shiftTypes: [],
        locationStrings: [],
      });
    }
  };

  const addSpecialtyUnit = (values: SelectItem[]) => {
    setSpecialtyUnits(prevState => [...values, ...prevState]);
  };

  const removeSpecialtyUnit = (values: SelectItem[]) => {
    setSpecialtyUnits(prevState =>
      prevState.filter(
        value =>
          !values.some(
            item => item.value === value.value && item.type === value.type
          )
      )
    );
  };

  const specialtyUnitsPlaceholder = useMemo(() => {
    return buildSpecialtyUnitPlaceholder(specialtyUnits);
  }, [specialtyUnits]);

  return (
    <div className="JobsFilter">
      <div className="JobsFilterContainer">
        <div className="JobsFilterTitle">
          <DSHeader size={3}>{filterTitle}</DSHeader>
        </div>
      </div>

      <div className="JobsFilterContainer">
        <div className="JobsFilterItem JobsFilterSelect">
          <JobsDropdownSelect
            handleChange={event => {
              const value = event.target.value as JOB_URL_TYPE;

              if (value) {
                setJobUrlType(value);
                setSpecialtyUnits([]);
              } else {
                setJobUrlType(value);
                setRole('');
                setSpecialtyUnits([]);
              }
            }}
            items={[
              { value: '', displayName: 'Job Type', disabled: true },
              {
                value: JOB_URL_TYPE.TravelAllied,
                displayName: 'Travel Allied',
              },
              { value: JOB_URL_TYPE.TravelNurse, displayName: 'Travel Nurse' },
            ]}
            value={jobUrlType}
          />
        </div>

        <div className="JobsFilterItem JobsFilterSelect">
          <JobsDropdownSelect
            disabled={!jobUrlType}
            handleChange={event => setRole(event.target.value as string)}
            items={[
              { value: '', displayName: 'All Roles' },
              ...filteredRoles.map(({ slug, filterName }) => ({
                value: slug || '',
                displayName: filterName,
              })),
            ]}
            value={role}
          />
        </div>

        <div className="JobsFilterItem JobsFilterDropdown">
          <AutoCompleteMultiSelect
            allItemsLabel="All Specialties/Units"
            focusedPlaceholder="Search Specialties/Units"
            disabled={!jobUrlType}
            filterItems={true}
            flatList={true}
            items={specialtyUnitItems}
            initialSelectedItems={specialtyUnits}
            placeholder={specialtyUnitsPlaceholder}
            onApply={handleFormSubmit}
            onClear={() => setSpecialtyUnits([])}
            onSelect={(items, selected) => {
              if (selected) {
                addSpecialtyUnit(items);
              } else {
                removeSpecialtyUnit(items);
              }
            }}
          />
        </div>
      </div>
      <div className="JobsFilterContainer">
        <div className="JobsFilterItem">
          <Button
            style={{
              minWidth: '7.5em',
              paddingBottom: '0.75em',
              paddingTop: '0.75em',
              border: 'none',
              width: '100%',
            }}
            size="medium"
            color="primary"
            shape="rounded"
            onClick={handleFormSubmit}
            disabled={!jobUrlType}
          >
            Search for jobs
          </Button>
        </div>
      </div>
      <style jsx>{`
        .JobsFilter {
          align-items: center;
          background-color: ${colors.white};
          border-radius: 0.5em;
          box-shadow: 0px 8px 24px rgba(36, 35, 41, 0.08);
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          justify-content: center;
          width: 50%;
          padding: 1.25em;
        }

        .JobsFilterContainer {
          display: flex;
          width: 100%;
          justify-content: center;
        }

        .JobsFilterTitle {
          text-align: center;
        }

        .JobsFilterItem {
          width: 100%;
          margin: 1.25em;
        }

        .JobsFilterContainer .JobsFilterItem {
          width: 30%;
        }

        .JobsFilterSelect {
          max-height: 2.5em;
        }

        .JobsFilterDropdown {
          max-height: 2.5em;
          width: 12.5em;
          min-width: 12.5em;
        }

        @media (max-width: ${MOBILE_MAX_WIDTH}) {
          .JobsFilter {
            flex-direction: column;
            width: 100%;
            padding: 1em;
          }

          .JobsFilterContainer {
            flex-direction: column;
          }

          .JobsFilterItem {
            margin: 0.625em 0;
          }

          .JobsFilterContainer .JobsFilterItem {
            width: 100%;
          }
        }

        @media (min-width: ${DESKTOP_MIN_WIDTH}) {
          .JobsFilter {
            margin-left: auto;
            margin-right: auto;
            max-width: 61em;
            width: 100%;
          }
        }

        @media screen and (min-width: 64em) {
          .JobsFilter {
            padding: 2.25em 0;
          }
        }
      `}</style>
    </div>
  );
}
