import { type ReactNode, useState } from 'react';

import TrustedWordmark from '@public/static/trusted_wordmark_white.svg';
import { SignupButton } from '@src/components/signup-button';
import { MOBILE_MAX_WIDTH_PX_VALUE } from '@src/constants';
import type { JobPageNavigation } from '@src/queries/jobs-page-content-query';
import * as colors from '@src/support/colors';
import { Button, FontAwesomeIcon } from '@src/ui';
import {
  ListItemText,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@src/ui/material-ui';
import Link from 'next/link';
import { MobileMenu } from './mobile-menu';

const LinkButton = ({
  name,
  href,
  children,
}: {
  name: string;
  children?: ReactNode;
  href?: string;
}) => (
  <Button
    component="a"
    color="white"
    variant="link"
    style={{ color: colors.white }}
    href={href}
  >
    {name}
    {children}
  </Button>
);

export function JobsPageHeader({
  pageNavigation = [],
}: {
  pageNavigation: JobPageNavigation[];
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(MOBILE_MAX_WIDTH_PX_VALUE)
  );

  return (
    <div className="JobsPageHeader">
      <div className="JobsPageHeaderTitle">
        <div className="JobsPageHeaderMobileTitle">
          <div className="JobsPageHeaderLogo">
            {isMobile && (
              <div className="JobsPageHeaderMobileTitleIcon">
                <Button onClick={() => setDrawerOpen(true)} variant="icon">
                  <FontAwesomeIcon
                    icon={['fal', 'bars']}
                    size="2x"
                    color={colors.white}
                  />
                </Button>
              </div>
            )}
            <a href="https://www.trustedhealth.com">
              <TrustedWordmark style={{ height: '100%', width: '11.25em' }} />
            </a>
          </div>

          {!isMobile && (
            <div className="JobsPageHeaderDesktop">
              {[...pageNavigation]
                .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
                .map(({ name, link, subNavigations, isParent }) => {
                  if (isParent && subNavigations && subNavigations.length > 0) {
                    return (
                      <div key={`parent_${name}_${isParent}_${link}`}>
                        <div className="Dropdown JobsPageHeaderItem">
                          <LinkButton name={name}>
                            <span className="JobsPageHeaderItemIcon">
                              <FontAwesomeIcon icon={['fas', 'angle-down']} />
                            </span>
                          </LinkButton>

                          <div className="DropdownContent">
                            <div className="DropdownContentBody">
                              {subNavigations.map(({ name, link }) => {
                                return (
                                  <MenuItem key={`sub_item_${name}_${link}`}>
                                    <ListItemText>
                                      <Link href={link} legacyBehavior>
                                        {name}
                                      </Link>
                                    </ListItemText>
                                  </MenuItem>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (isParent) {
                    return (
                      <div
                        className="JobsPageHeaderItem"
                        key={`is_parent_${name}_${isParent}_${link}`}
                      >
                        <LinkButton href={link} name={name} />
                      </div>
                    );
                  }
                })}
            </div>
          )}
          <SignupButton source="external-jobs" />
        </div>
      </div>

      <MobileMenu
        drawerOpen={drawerOpen}
        pageNavigation={pageNavigation}
        onClose={() => setDrawerOpen(false)}
      />

      <style jsx>{`
        .JobsPageHeader {
          display: flex;
          flex-direction: column;
        }

        .JobsPageHeaderTitle {
          display: flex;
          font-size: 1em;
          font-weight: bold;
        }

        .JobsPageHeaderLogo {
          align-items: center;
          display: flex;
        }

        .JobsPageHeaderDesktop {
          display: flex;
          justify-content: end;
          width: 100%;
        }

        .JobsPageHeaderMobileTitle {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .JobsPageHeaderMobileTitleIcon {
          margin-right: 0.9375em;
        }

        .JobsPageHeaderItem {
          margin-right: 2.5em;
        }

        .JobsPageHeaderItemIcon {
          display: flex;
          margin-left: 0.3125em;
        }

        .JobsPageHeaderTitle:not(.with-data) {
          align-items: center;
          justify-content: space-between;
        }

        .Dropdown {
          display: inline-block;
          position: relative;
        }

        .DropdownContent {
          background-color: transparent;
          display: none;
          min-width: 10em;
          position: absolute;
          z-index: 1;
        }

        .DropdownContentBody {
          background-color: ${colors.white};
          border-radius: 0.1875em;
          border: 0.0625em solid ${colors.neutralLower};
          box-shadow: 0 0.25em 0.5em rgba(36, 35, 41, 0.08);
          margin-top: 0.625em;
        }

        .Dropdown:hover .DropdownContent {
          display: block;
        }
      `}</style>
    </div>
  );
}
