import {
  InputAdornment,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@src/ui/material-ui';

import { white } from '@src/support/colors';
import type { ReactElement } from 'react';

type Item = {
  value: string | undefined;
  displayName: string | ReactElement;
  disabled?: boolean;
};

type ValueType = number | string | undefined;

type JobsDropdownSelectType = {
  handleChange: (event: SelectChangeEvent<ValueType>) => void;
  value?: ValueType;
  items: Item[];
  icon?: ReactElement;
  disabled?: boolean;
};

export const JobsDropdownSelect = ({
  handleChange,
  value,
  items,
  icon,
  disabled = false,
}: JobsDropdownSelectType) => (
  <>
    <Select
      SelectDisplayProps={{ style: { paddingLeft: '1rem' } }}
      fullWidth
      disabled={disabled}
      value={value}
      onChange={handleChange}
      displayEmpty
      inputProps={{ size: 'small' }}
      style={{ height: '100%' }}
      variant="outlined"
      MenuProps={{
        disableScrollLock: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      startAdornment={
        icon ? <InputAdornment position="start">{icon}</InputAdornment> : null
      }
    >
      {items.map(({ value, displayName, disabled }) => (
        <MenuItem key={value} value={value} disabled={disabled}>
          {displayName}
        </MenuItem>
      ))}
    </Select>
    <style jsx global>
      {`
        .MuiSelect-root.MuiOutlinedInput-input {
          padding: 0.625rem 2rem 0.625rem 0.875rem;
        }

        .MuiSelect-root.MuiSelect-select:focus {
          background-color: ${white};
        }
      `}
    </style>
  </>
);
