import type { ReactNode } from 'react';

import { DESKTOP_MIN_WIDTH } from '@src/constants/breakpoints';

export function DirectoryHeaderBar(props: { children?: ReactNode }) {
  return (
    <div className="DirectoryHeaderBar">
      {props.children}
      <style jsx>{`
        .DirectoryHeaderBar {
          padding: 1.25em;
        }

        @media (min-width: ${DESKTOP_MIN_WIDTH}) {
          .DirectoryHeaderBar {
            margin-left: auto;
            margin-right: auto;
            max-width: 75em;
            padding: 1.25em;
            width: 100%;
          }
        }

        @media screen and (min-width: 64em) {
          .DirectoryHeaderBar {
            padding: 1.25em 0;
          }
        }

        @media screen and (min-width: 120em) {
          .DirectoryHeaderBar {
            padding: 1.25em 0;
            max-width: 87.5em;
          }
        }
      `}</style>
    </div>
  );
}
