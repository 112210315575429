import { APIProvider } from '@vis.gl/react-google-maps';
import getConfig from 'next/config';
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type Service = google.maps.places.AutocompleteService;

const { GOOGLE_MAPS_API_KEY } = getConfig().publicRuntimeConfig;

const Context = createContext<
  readonly [service: Service | undefined, loading: boolean]
>([undefined, true]);

const libraries = ['places'];

export const GoogleMapsScriptProvider = ({
  children,
  onLoad,
}: PropsWithChildren<{ onLoad?: () => void }>) => {
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState<Service | undefined>();

  const mapsLoaded = useCallback(() => {
    // window.google isn't set until the script loads
    if (!service) {
      setService(new window.google.maps.places.AutocompleteService());
      setLoading(false);
    }
    onLoad?.();
  }, [service, onLoad]);

  const value = useMemo(() => [service, loading] as const, [loading, service]);

  return (
    <Context.Provider value={value}>
      <APIProvider
        apiKey={GOOGLE_MAPS_API_KEY}
        libraries={libraries}
        region="US"
        onLoad={mapsLoaded}
      >
        {children}
      </APIProvider>
    </Context.Provider>
  );
};

export const useGoogleMapsScript = () => useContext(Context);
