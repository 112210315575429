import { first } from 'lodash';

function getNumberFromRomanChar(value: string) {
  switch (value) {
    case 'm':
    case 'M':
      return 1000;
    case 'd':
    case 'D':
      return 500;
    case 'c':
    case 'C':
      return 100;
    case 'l':
    case 'L':
      return 50;
    case 'x':
    case 'X':
      return 10;
    case 'v':
    case 'V':
      return 5;
    case 'i':
    case 'I':
      return 1;
    default:
      return 0;
  }
}

export function romanNumeralToNumber(value: string) {
  const array = value.split('');
  let total = 0;
  let current: string;
  let currentValue: number;
  let next: string;
  let nextValue: number;

  for (let i = 0; i < array.length; i++) {
    current = array[i];
    currentValue = getNumberFromRomanChar(current);
    next = array[i + 1];
    nextValue = getNumberFromRomanChar(next);

    if (currentValue < nextValue) {
      total -= currentValue;
    } else {
      total += currentValue;
    }
  }

  return total;
}

export function numberToRomanNumeral(value: number) {
  if (value === 0) throw TypeError('value cannot be zero');

  let state = value;
  let result = '';
  while (state !== 0) {
    if (state >= 1000) {
      // 1000 - m
      result += 'm';
      state -= 1000;
    } else if (state >= 900) {
      // 900 -  cm
      result += 'cm';
      state -= 900;
    } else if (state >= 500) {
      // 500 - d
      result += 'd';
      state -= 500;
    } else if (state >= 400) {
      // 400 -  cd
      result += 'cd';
      state -= 400;
    } else if (state >= 100) {
      // 100 - c
      result += 'c';
      state -= 100;
    } else if (state >= 90) {
      // 90 - xc
      result += 'xc';
      state -= 90;
    } else if (state >= 50) {
      // 50 - l
      result += 'l';
      state -= 50;
    } else if (state >= 40) {
      // 40 - xl
      result += 'xl';
      state -= 40;
    } else if (state >= 10) {
      // 10 - x
      result += 'x';
      state -= 10;
    } else if (state >= 9) {
      // 9 - ix
      result += 'ix';
      state -= 9;
    } else if (state >= 5) {
      // 5 - v
      result += 'v';
      state -= 5;
    } else if (state >= 4) {
      // 4 - iv
      result += 'iv';
      state -= 4;
    } else if (state >= 1) {
      // 1 - i
      result += 'i';
      state -= 1;
    }
  }

  return result;
}

/**
 * Given a value or array of values, return a single value. Uses the first element if it is an array.
 */
export function flatten<T>(strOrArr: T | T[] | undefined) {
  if (Array.isArray(strOrArr)) {
    return first(strOrArr);
  }

  return strOrArr;
}
